import {defineStore} from 'pinia'

export const useLanguageStore = defineStore('language', {


    state: () => ({
        isOpenLanguageSwitchModal: false,
    }),


})

