import {defineStore} from 'pinia'

export const useAccessStore = defineStore('access', {


    state: () => ({
        passwordEnteredByUser: '',
        isOpenPasswordModal: false,
        isOpenTimeCheckModal: false,
        timeCheckMessage: false,
    }),

   persist: {
        storage: localStorage,
        paths: ['passwordEnteredByUser' ],
    },
})

