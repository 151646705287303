import {defineStore} from 'pinia'


export const useConfigStore = defineStore('config', {


    state: () => ({
        storeVersion: null,
        storeVersionCurrent: null,
        activeLocale: null,
        activeObject: null,
        activeClient: null,
        activeComponents: [],
        languages: [],
        isOpenTermsModal: false,
    }),

    actions: {
        setActiveLocale(activeLocale) {
            try {
                this.activeLocale = activeLocale;
            } catch (error) {
                console.error("Failed to set active language:", error);
            }
        },
        setActiveObject(activeObject) {
            try {
                this.activeObject = activeObject;
            } catch (error) {
                console.error("Failed to set active object:", error);
            }
        },
        setActiveClient(activeClient) {
            try {
                this.activeClient = activeClient;
            } catch (error) {
                console.error("Failed to set active object:", error);
            }
        },
        setLanguages(languages) {
            try {
                // Filter the languages to include only those that are enabled
                this.languages = languages.filter(language => language.enabled);
            } catch (error) {
                console.error("Failed to set languages:", error);
            }
        },
        isVoiceEnabledForLanguage() {
            try {
                // Find the language object in the languages array
                const languageObject = this.languages.find(lang => lang.value === this.activeLocale);

                // If the language object is found and its enabledVoice property is true, return true
                return languageObject ? languageObject.enabledVoice : false;
            } catch (error) {
                console.error("Error checking if voice is enabled for language:", error);
                return false; // Return false in case of an error
            }
        },
        setActiveComponents(activeComponents) {
            try {
                // Filter the languages to include only those that are enabled
                this.activeComponents = activeComponents;
            } catch (error) {
                console.error("Failed to set activeComponents:", error);
            }
        },
        //this exists to check if the store version has changed
        setStoreVersionCurrent(storeVersionCurrent) {
            try {
                // Filter the languages to include only those that are enabled
                this.storeVersionCurrent = storeVersionCurrent;
            } catch (error) {
                console.error("Failed to set languages:", error);
            }
        },
    },

    persist: {
        storage: localStorage,
        paths: ['activeLocale', 'activeObject','activeClient', 'languages', 'storeVersion', 'activeComponents'],
    },
})


