import {defineStore} from 'pinia'

export const useObjectStore = defineStore('object', {


    state: () => ({
        objectData: {},
        attributions: [],
    }),

    actions: {
        async setObjectData(object) {
            try {
                this.objectData = object;
            } catch (error) {
                console.error("Failed to set object data:", error);
            }
        },
        async setAttributions(attributions) {
            try {
                this.attributions = attributions;
            } catch (error) {
                console.error("Failed to set attributions:", error);
            }
        },
    },

    persist: {
        storage: localStorage,
        paths: ['objectData', 'attributions'],
    },
})

