import {defineStore} from 'pinia'
import {useForm} from "@inertiajs/vue3";


export const useQuestionStore = defineStore('question', {
    state: () => ({
        formData: useForm({
            question: '',
            localeId: '',
        }),

        suggestionsCurrent: [],
        suggestionsFull: [],
        isLoadingQuestion: false,

        jargon: [],
        jargonActive: null,
    }),

      actions: {
        async setSuggestedQuestions(suggestedQuestions) {
            try {
                this.suggestionsFull = suggestedQuestions;
            } catch (error) {
                console.error("Failed to set suggested question:", error);
            }
        },
    },

     persist: {
        storage: localStorage,
        paths: ['suggestionsFull'],
    },
})

