import {useConfigStore} from "@/Stores/configStore.js";
import {useQuestionStore} from "@/Stores/questionStore.js";
import {useObjectStore} from "@/Stores/objectStore.js";
import {useLanguageStore} from "@/Stores/languageStore.js";
import {useAccessStore} from "@/Stores/accessStore.js";
import {useNavStore} from "@/Stores/navStore.js";
import {useTeachingBubbleStore} from "@/Stores/teachingBubblesStore.js";
import {useAnswerStore} from "@/Stores/answerStore.js";

const stores = () => {
    const configStore = useConfigStore();
    const questionStore = useQuestionStore();
    const answerStore = useAnswerStore();
    const objectStore = useObjectStore();
    const languageStore = useLanguageStore();
    const accessStore = useAccessStore();
    const navStore = useNavStore();
    const teachingBubbleStore = useTeachingBubbleStore();

    return {
        configStore,
        questionStore,
        answerStore,
        objectStore,
        languageStore,
        accessStore,
        navStore,
        teachingBubbleStore
    }
}

export default stores
