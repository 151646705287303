import {defineStore} from 'pinia'

export const useNavStore = defineStore('nav', {


    state: () => ({
        activePage: null,
    }),


    actions: {
        async setActivePage(activePage) {
            try {
                this.activePage = activePage;
            } catch (error) {
                console.error("Failed to set active page:", error);
            }
        },
    }

})

