import {defineStore} from 'pinia'

export const useTeachingBubbleStore = defineStore('teachingBubbles', {


    state: () => ({
        teachingBubblesClosed: {
            openQuestions: false,
            closedQuestions: false,
            jargon: false,
            buttonGoToContent: false,
        }
    }),

    actions: {
        async registerClosedTeachingBubble(teachingBubbleId) {
            try {
                this.teachingBubblesClosed[teachingBubbleId] = true;
            } catch (error) {
                console.error("Failed register closed bubble:", error);
            }
        },
        // Add this new action
        resetAllTeachingBubbles() {
            try {
                Object.keys(this.teachingBubblesClosed).forEach((key) => {
                    this.teachingBubblesClosed[key] = false;
                });
            } catch (error) {
                console.error("Failed to reset teaching bubbles:", error);
            }
        },
    },

    persist: {
        storage: localStorage,
        paths: ['teachingBubblesClosed'],
    },
})

